import React, { Component } from 'react'
import Layout from '../../layouts'
import style from './zimmer.module.css'

import kitchen from './kueche.jpg'
import gameroom from './spielzimmer.jpg'
import schlafzimmerNord from './schlafzimmer-nord.jpg'
import schlafzimmerMitte from './schlafzimmer-mitte.jpg'
import schlafzimmerSued from './schlafzimmer.jpg'
import FloorPlanTop from './FloorPlanTop'
import FloorPlanBottom from './FloorPlanBottom'
import Lightbox from 'react-images'

export default class Zimmer extends Component {
  constructor(props) {
    super(props)

    this.state = { shownRoom: null }
    this.images = {
      kitchen: [{ src: kitchen, caption: 'Küche', alt: 'Küche' }],
      gameroom: [{ src: gameroom, caption: 'Spielzimmer', alt: 'Spielzimmer' }],
      sleepingRoom3: [
        { src: schlafzimmerNord, caption: 'Schlafzimmer', alt: 'Schlafzimmer' },
      ],
      sleepingRoom2: [
        {
          src: schlafzimmerMitte,
          caption: 'Schlafzimmer',
          alt: 'Schlafzimmer',
        },
      ],
      sleepingRoom1: [
        { src: schlafzimmerSued, caption: 'Schlafzimmer', alt: 'Schlafzimmer' },
      ],
    }
  }

  render() {
    const { shownRoom } = this.state
    return (
      <Layout>
        <div className={style.rooms}>
          <h2 className={style.h2}>Zimmer</h2>
          <FloorPlanBottom
            onRoomClick={shownRoom => this.setState({ shownRoom })}
          />
          <FloorPlanTop
            onRoomClick={shownRoom => this.setState({ shownRoom })}
          />
          <Lightbox
            backdropClosesModal
            isOpen={!!shownRoom}
            showImageCount={false}
            images={(!!shownRoom && this.images[shownRoom]) || []}
            onClose={() => this.setState({ shownRoom: null })}
          />
        </div>
      </Layout>
    )
  }
}
